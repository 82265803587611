import BillingHistoryChart from '@/views/dashboard/main/BillingHistoryChart.vue';
import store from "@/state/store.js";
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Message summary reports',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  components: {
    BillingHistoryChart
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    messageSummaryData() {
      return store.getters['admin/reporting/messageSummaryData'];
    }
  },
  mounted() {
    this.loading = true;
    store.dispatch('admin/reporting/getMessageSummarry')
    // .then( res => console.info(res))
    .finally(() => this.loading = false);
  }
};