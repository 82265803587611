var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "row"
  }, [_vm.loading ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row mt-5 pt-5"
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "col-md-12"
  })])]) : _vm._e(), !_vm.loading ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-10"
  }, [_c('BillingHistoryChart', {
    attrs: {
      "categoriesData": _vm.messageSummaryData[0],
      "totalRecipients": _vm.messageSummaryData[1]
    }
  })], 1)])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };